import * as React from 'react';
import { PageContextProps } from '../../utils/graphql';
import RssButton from '../buttons/RssButton';
import MailSubscription from './mail-subscription';

type NewsFooterProps = {
  pageContext: PageContextProps;
};

const NewsFooter: React.VoidFunctionComponent<NewsFooterProps> = ({ pageContext }) => (
  <div className="container px-4 py-8 text-center sm:px-8">
    <RssButton />
    <MailSubscription locale={pageContext.locale} />
  </div>
);

export default NewsFooter;
