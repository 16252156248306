import React from 'react';
import { useTranslation } from 'react-i18next';
import { Post, PageContextProps } from '../../utils/graphql';
import useFilteredContent from '../../utils/useFilteredContent';
import PostItem from './post-item';

type PostsListProps = {
  posts: Post[];
  siteUrl: string;
  pageContext: PageContextProps;
};

const PostsList: React.VoidFunctionComponent<PostsListProps> = ({
  posts,
  siteUrl,
  pageContext,
}) => {
  const { t } = useTranslation('news');

  const { filteredContent, filterComponents } = useFilteredContent(posts, ['theme'], t, true);
  const LAST_CONTENT_NUMBER = 2;
  const lastContents = filteredContent.slice(0, LAST_CONTENT_NUMBER);
  const otherContents = filteredContent.slice(LAST_CONTENT_NUMBER);
  const noPostItem = (content: Post[]) => {
    if (content.length !== 0) return null;
    return (
      <div className="mb-3 mt-6 text-xl font-semibold text-black lg:mb-7 lg:mt-14 lg:text-2xl">
        {t('render.no-post-result')}
      </div>
    );
  };
  const postItems = (i18key: string, content: Post[]) => {
    if (content.length === 0) return null;
    return (
      <>
        <div className="mb-3 mt-6 text-xl font-semibold text-black lg:mb-7 lg:mt-14 lg:text-2xl">
          {t(i18key)}
        </div>
        <div className="flex flex-wrap justify-center lg:justify-start">
          {content.map((post) => {
            return (
              <PostItem
                key={`${post.locale}/${post.slug}`}
                siteUrl={siteUrl}
                post={post}
                lang={pageContext.locale}
              />
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      {filterComponents}
      {noPostItem(filteredContent)}
      {postItems('listSeparators.ourLastPublications', lastContents)}
      {postItems('listSeparators.allPublications', otherContents)}
    </>
  );
};

export default PostsList;
