import { useLocalization } from 'gatsby-theme-i18n';
import { Post } from './graphql';

const useLocalizedPosts = (allPosts: Post[]): Post[] => {
  const { locale, config } = useLocalization();
  const posts: Post[] = [];
  allPosts.forEach((post) => {
    const lang = config.find((loc) => loc.code === post.locale);
    // Enrich with lang field if it is a managed locale but not the expected one
    if (lang) {
      if (post.locale !== locale) {
        // eslint-disable-next-line no-param-reassign
        post.lang = post.locale;
      }
    } else {
      // Exclude unsupported localed
      return;
    }
    // We list posts that are either:
    // - in expected locale
    // - in another locale but does not exist in expected locale
    if (
      post.locale === locale ||
      !allPosts.find((p) => p.slug === post.slug && p.locale === locale)
    ) {
      posts.push(post);
    }
  });

  return posts;
};

export default useLocalizedPosts;
