import { graphql } from 'gatsby';
import AuthorPosts from '../components/news/author-posts';

export default AuthorPosts;

// SYNC-235ODO993XCEDZOEF023
// Any modifications in this graphql should be implemented also in this SYNC number
export const query = graphql`
  query ($locale: String!, $normalizedFullName: String!, $postsPublishedFilter: [Boolean]) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          name
          url
        }
      }
    }
    authorJson(normalizedFullName: { eq: $normalizedFullName }) {
      fullName
      normalizedFullName
      bio(locale: $locale)
      avatar {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 48, height: 48, placeholder: NONE)
        }
      }
    }
    allMdx(
      filter: {
        fields: { source: { eq: "news" }, slug: { ne: null } }
        frontmatter: {
          authors: { elemMatch: { normalizedFullName: { eq: $normalizedFullName } } }
          type: { ne: null }
          Published: { in: $postsPublishedFilter }
        }
      }
      sort: { fields: [fields___date, frontmatter___title], order: DESC }
      limit: 1000
    ) {
      nodes {
        timeToRead
        excerpt
        frontmatter {
          title
          abstract
          duration
          tags
          theme
          type
          externalUrl
        }
        fields {
          slug
          date
          locale
          socialImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 432, backgroundColor: "transparent")
            }
          }
        }
      }
    }
  }
`;
